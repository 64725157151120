@import "_settings/globalSetting";

.cc-login-modal {
    .modal-dialog {
        margin: rem-calc(30) auto;
        max-width: rem-calc(480);
        overflow-y: auto;
        top: 50%;
        transform: translateY(-50%) !important;

        @include media-breakpoint-down(xs) {
            top: unset;
            bottom: -100%;
            transform: translateY(-100%) !important;
        }

        p:empty {
            display: none;
        }
    }

    .modal-content {
        border-radius: rem-calc(2);
        border: rem-calc(1) solid $black;
        padding: rem-calc(24);

        @include media-breakpoint-down(xs) {
            padding: 0 rem-calc(16) rem-calc(16);
        }
    }

    .modal-header {
        border: 0;
        border-radius: 0;
        display: block;
        padding: 0;
        margin-top: rem-calc(32);
        margin-bottom: rem-calc(12);

        @include media-breakpoint-down(xs) {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            margin-top: rem-calc(48);
        }

        .close {
            font-size: rem-calc(14);
            position: absolute;
            right: rem-calc(24);
            top: rem-calc(24);
            z-index: 1;

            @include media-breakpoint-down(xs) {
                right: rem-calc(16);
            top: rem-calc(16);
            }

            .icon-close-remove {
                font-size: rem-calc(14);
            }
        }

        .modal-title {
            color: $black;
            font-size: rem-calc(22);
            font-weight: $primary-font-semibold;
            line-height: rem-calc(22);
            position: relative;

            // &::after {
            //     background: $red1;
            //     bottom: 0;
            //     content: '';
            //     display: block;
            //     height: rem-calc(1);
            //     left: 50%;
            //     margin-left: rem-calc(-20);
            //     position: absolute;
            //     width: rem-calc(40);
            // }
        }
    }

    .modal-body {
        color: $black;
        font-size: rem-calc(15);
        font-weight: $primary-font-regular;
        line-height: rem-calc(22);
        padding: 0;

        .small-paragraph {
            font-size: rem-calc(11);
            line-height: rem-calc(16);
            margin-bottom: 0;
        }

        @include media-breakpoint-down(xs) {
            margin: 0 auto;
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .request-password-body {
        p {
            margin-bottom: rem-calc(24);
        }
    }

    .cc-content-button-back {
        max-width: 100%;
    }

    .cc-modal-description {
        color: $black;
        display: block;
        font-family: $primary-font;
        font-size: rem-calc(15);
        font-weight: $primary-font-regular;
        line-height: rem-calc(22);
        margin-bottom: rem-calc(32);
    }

    .cc-form-group {
        padding-bottom: 0;
    }

    .cc-button-type-1 {
        //margin-top: rem-calc(40);
    }

    .cc-modal-footer-info {
        color: $black;
        display: block;
        font-size: rem-calc(14);
        font-weight: $primary-font-regular;
        margin-bottom: 0;
        margin-top: rem-calc(25);

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    .cc-forgot-password-content-form {
        #reset-password-email {
            margin-bottom: rem-calc(16);

            @include media-breakpoint-down(xs) {
                margin-bottom: rem-calc(24);
            }
        }
    }
}
