@import "_settings/globalSetting";
@import "./forgot-paasword-modal";

.cc-login {
    max-width: rem-calc(994);
    padding-top: rem-calc(40);

    @include media-breakpoint-down(xs) {
        padding-top: rem-calc(32);
        max-width: 100%;
    }

    .cc-login-main {
        padding-bottom: rem-calc(80);

        @include media-breakpoint-down(xs) {
            padding-bottom: rem-calc(40);
        }
    }

    .cc-login-title {
        color: $black;
        display: block;
        font-size: rem-calc(25);
        font-weight: $primary-font-semibold;
        line-height: rem-calc(30);
        margin: 0;
        padding-bottom: rem-calc(40);
        text-align: center;
        display: none;

        @include media-breakpoint-down(xs) {
            padding-bottom: rem-calc(30);
        }
    }

    .cc-login-subtitle {
        margin: 0;
        padding-bottom: rem-calc(10);
        font-size: rem-calc(24);
        line-height: rem-calc(30);
        font-family: $primary-font;
        font-weight: $primary-font-bold;

        @include media-breakpoint-down(xs) {
            padding-bottom: rem-calc(6);
        }

        &.cc-h2,
        &.cc-h3 {
            text-transform: none;
        }

        &-second {
            margin-top: rem-calc(40);
        }
    }

    .cc-login-intro-text {
        color: $black;
        display: block;
        font-size: rem-calc(15);
        font-weight: $primary-font-regular;
        line-height: rem-calc(22);
        margin-bottom: rem-calc(32);
        text-align: left;

        &.cc-login-intro-text-registration {
            margin-bottom: rem-calc(40);
        }
    }

    .cc-login-content-remember {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: rem-calc(25);

        .cc-form-group {
            width: auto;

            .cc-content-checkbox {
                margin-bottom: rem-calc(40);
                padding-left: rem-calc(3);

                .cc-check-label {
                    font-size: rem-calc(13);
                }
            }
        }

        .cc-pull-right {
            margin-left: auto;
            text-align: right;
        }

        .cc-forgot-password {
            display: block;
            font-family: $primary-font;
            font-size: rem-calc(13);
            font-weight: $primary-font-medium;
            line-height: rem-calc(18);
            padding: 0;
            text-decoration: underline;
        }
    }

    .cc-login-benefits {
        .cc-login-benefit {
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-bottom: rem-calc(20);

            .cc-content-icon {
                width: rem-calc(40);

                .cc-icon {
                    display: block;
                    height: rem-calc(40);
                    width: rem-calc(40);
                }
            }

            .cc-text {
                color: $black;
                display: block;
                flex: 0 0 calc(100% - 55px);
                font-size: rem-calc(14);
                font-weight: $primary-font-regular;
                line-height: rem-calc(22);
                margin-left: auto;
                max-width: calc(100% - 55px);
                width: calc(100% - 55px);

                &.cc-nomargin {
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                }
            }
        }

        &-ma {
            .cc-login-benefit {
                margin-bottom: rem-calc(16);
            }
        }

        .cc-content-link {
            font-size: 0;
            padding: rem-calc(7) 0 rem-calc(34);
            text-align: left;

            &-last {
                padding-bottom: 0;
            }

            a {
                color: $black;
                display: inline-block;
                font-size: rem-calc(14);
                font-weight: $primary-font-semibold;
                line-height: rem-calc(18);
                text-decoration: underline;
                text-transform: uppercase;
            }
        }
    }

    .cc-login-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: rem-calc(24) rem-calc(-15) 0;

        @include media-breakpoint-down(xs) {
            margin: 0;
        }

        .cc-login-col {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 rem-calc(4);
            width: 50%;

            &.cc-login-col-login {
                border-right: rem-calc(1) solid $grey2;
                padding-right: rem-calc(64);

                @include media-breakpoint-down(xs) {
                    border-right: none;
                    border-bottom: rem-calc(1) solid $grey2;
                    padding: 0 0 rem-calc(32) 0;
                }

                .cc-login-subtitle-second {
                    padding-bottom: rem-calc(20);
                }
            }

            &.cc-login-col-register {
                font-size: rem-calc(13);
                padding-left: rem-calc(64);

                @include media-breakpoint-down(xs) {
                    padding: 0;
                    padding-top: rem-calc(32);
                }

                .cc-login-benefits {
                    margin-bottom: rem-calc(16);
                }

                .cc-login-benefit {
                    align-items: center;
                    display: flex;
                    margin-bottom: rem-calc(10);
                }

                .cc-content-icon {
                    margin-right: rem-calc(10);

                    &::before {
                        color: $red1;
                        font-size: rem-calc(40);
                    }
                }
            }

            @include media-breakpoint-down(xs) {
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
            }
        }
    }

    // import modal forgot password
    @extend .cc-login-modal;

    form {
        .cc-form-password {
            margin-top: rem-calc(16)
        }

        .alert {
            &.alert-danger {
                background: transparent;
                border: rem-calc(1) solid $danger;
                border-radius: 0;
                color: $danger;
                font-family: $primary-font;
                font-size: rem-calc(12);
                font-weight: $primary-font-medium;
                margin-bottom: rem-calc(30);
                padding: rem-calc(15) rem-calc(10);
                text-align: center;
            }
        }
    }


    .cc-form-description {
        display: block;
        font-family: $primary-font-medium;
        font-size: rem-calc(12);
        line-height: rem-calc(17);
        color: $grey1;
        text-align: left;
        margin: rem-calc(0) 0 rem-calc(30) 0;

        &.cc-login-modal-checkout-description {
            display: none !important;
        }
    }
}
